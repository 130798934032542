.create-user {
  position: relative;
  background-color: #ffffff;
  height: max-content;
  border-radius: 10px;
  max-width: 1000px;

  .event-footer {
    position: absolute;
    width: 100%;
    left: 12px;
  }
}

.width-fit {
  width: fit-content;
}

.status-label {
  width: 78px;
  min-width: 78px;
  max-width: 78px;
  height: 22px;
  color: #fff;
  border-radius: 2px;
  font-size: 14px;
  text-align: center;
}
