.dropdown {
    display: inline-flex;
    .dropdown-label {
        overflow: hidden;
        text-overflow: ellipsis;
        &:disabled {
            background-color: #fff;
            color: #979797;
            border: 1px solid #979797;
        }
    }
   
}
