.toast-container {
  display: flex;
  padding: 8px 0px 8px 8px;

  img {
    margin-top: 4px;
  }

  .toast-content {
    margin-left: 8px;

    h2 {
      font-size: 13px;
      font-weight: bold !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.33 !important;
      letter-spacing: 0.35px !important;
      color: #25262b;
      margin-bottom: 8px;
    }

    .no-internet {
      font-size: 25px !important;
      color: #ff000d !important;
    }

    p {
      font-size: 12px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.33 !important;
      letter-spacing: 0.25px !important;
      color: #25262b !important;
      margin-bottom: 0px;
    }
  }
}

.Toastify__close-button {
  &:focus {
    outline: 0px !important;
  }
}

.Toastify__toast-container {
  width: max-content !important;
  min-width: 250px;
}
