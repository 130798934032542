.user-list-container {
  background-color: white;
  min-height: 80vh;
  max-height: 80vh;

  .user-list-header {
    font-weight: 700;
    font-size: 16px;
    color: #9da4aa;
  }

  .user-body {
    overflow-x: auto;
    height: 100%;
    max-height: calc(80vh - 90px);
  }

  .badge {
    min-width: 78px;
  }
}

@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.Admin_Center_Container {
  background-color: $bg-quaternary-color;
  box-shadow: 0 0 40px rgba(33, 33, 33, 0.2);
  margin-top: 40px;
}
.User_Role {
  padding: 10px;
}

@import '../../../styles/colors';
.Submit_Btn {
  height: fit-content;
  padding: 8px 30px;
  background-color: $bg-quaternary-color;
  color: $font-primary-color;
  border: 1px solid $font-primary-color;
  border-radius: 4px;
  transition: ease-in-out background-color 250ms, ease-in-out color 250ms;
  &:focus-visible {
    outline: none;
    background-color: $font-primary-color;
    border: 1px solid $font-primary-color;
    color: $font-quaternary-color;
  }
  &:focus {
    box-shadow: none;
  }
}

.Submit_Btn:hover {
  cursor: pointer;
  background-color: $bg-primary-color;
  border: 1px solid $font-primary-color;
  color: $font-quaternary-color;
}
.disabled {
  background-color: #ee9988;
  border: 1px solid #ee9988;
}
.disabled:hover {
  cursor: pointer;
  background-color: white;
  border: 1px solid #ee9988;
  color: #ee9988;
}
.Table_Row:hover {
  border: 2px solid #40a6ff;
  border-radius: 10px;
  cursor: pointer;
}
.Table_Row {
  height: 70px;
  border: 2px solid transparent;
  border-radius: 10px;
}

.active-user {
  background-color: #f0d3d3 !important;
  border-radius: 5px !important;
}
