.gamification-preview {
  background-color: white;
  height: fit-content;
  top: 96px;
  text-align: left;
  border-radius: 10px;
  padding-bottom: 15px;
}

.right-border {
  border-right: 1px solid black;
}

.overflow-handle {
  overflow-wrap: break-word;
}

.bold-text {
  font-weight: bold;
}

.custom-table-header{
  padding-right: 35px;
  
}

.podium-label {
  width: 55px;
  height: fit-content;
  text-align: center;
  padding: 0px;
  background: #e8e8e8;
  border: 1px solid #253645;
  border-radius: 10px;
  font-weight: bold
}

.selected {
  background: #ffeaea;
  border-color: #ff0000;
}

.preview-text {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: #9da4aa;
  justify-content: center;
}

.preview-helper-text {
  text-align: left;
  padding-left: 24px;
}

.seprator {
  background-color: #e5e5e5;
  height: 20px;
}

.custom-table-header-options {
  padding-right: 85px;
}
