@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.Preview_Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border: 10px solid $font-tertiary-color;
  width: 220px;
  border-radius: 30px;
}
.Preview_Image {
  width: 100%;
  height: 100%;
  position: relative;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.Preview_Image_Wrapper {
  position: relative;
  height: 346px;
  background-color: #e5e5e5;
  border-radius: 20px;
}
.Bottom_Nav {
  max-width: 100%;
  border-radius: 30px;
}
.Preview_Center_Container {
  background-color: $bg-quaternary-color;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border-radius: 10px;
}
.Preview_Main_Container {
  margin-top: 40px;
  margin-bottom: 15px;
  height: 46px;
  margin-left: auto;

  .provider-button {
    width: fit-content;
  }
}
.w2w-wraper {
  background-color: white;
  border-radius: 10px;

  .help-text {
    font-size: xx-small;
    color: #989898;
  }
  .bar {
    width: 170px;
    height: 28px;
    border-radius: 5px;
    img {
      background-color: white !important;
      border-radius: 1px;
      width: 40px;
      height: 22px;
      margin-top: 5px;
    }
  }
  .bar-text {
    font-size: x-small;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
}
