@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.Center_Container {
  background-color: $bg-quaternary-color;
  box-shadow: 0 0 40px rgba(33, 33, 33, 0.2);
  margin-top: 24px;
}
.Event_Name {
  margin-top: 40px;
  font-size: 26px;
  line-height: 100%;
  height: 22px;
  overflow-wrap: break-word;
}
.categories-label {
  width: fit-content;
  min-width: 155px;
  height: fit-content;
  line-height: 18px;
  text-align: center;
  padding: 10px 10px 15px;
  margin: 10px 10px 0px 0px;
  background: #e8e8e8;
  border: 1px solid #253645;
  border-radius: 10px;
  font-weight: bold;
}
.activeTab {
  background: #ffeaea !important;
  border-color: #ff0000 !important;
}
.example-custom-input {
  width: 100%;
  padding: 10px 15px;
  background-color: $bg-senary-color;
  color: $font-tertiary-color;
  border: 1px solid $font-secondary-color;
  border-radius: 4px;
  transition: ease-in-out background-color 250ms, ease-in-out color 250ms;
  &:focus-visible {
    outline: none;
    background-color: $font-tertiary-color;
    border: 1px solid $font-secondary-color;
    color: $font-tertiary-color;
  }
  &:disabled {
    background-color: #fff;
    color: #979797;
    border: 1px solid #979797;
  }
}
.DateTime {
  margin-bottom: 20px;
}
.image-label-size {
  font-size: 12px;
}
.image-upload {
  display: flex;
  gap: 10rem;
}
