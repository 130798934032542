.custom-header {
  font-size: 15px;
  border-radius: 10px;
  font-weight: 700;
  color: #9da4aa;
  border-bottom: 1px solid #e4e4f1;
}

.center {
  margin-top: 20px;
  text-align: center;
}

.poll-section-PreEvent {
  .center {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
  }

  .status-info {
    .status-info-title {
      font-weight: 700;
    }

    .color-box {
      width: 12px;
      height: 12px;
      border: 1px solid #c4c4c4;
    }
  }

  .bold-text {
    font-weight: 700;
  }

  th {
    padding: 5px 30px 5px 30px;
  }

  td {
    padding: 5px 30px 5px 30px;
    justify-content: center;

    input {
      margin-bottom: 0px;
    }
  }

  .Table_Row_Events {
    border-radius: 5px;
    height: fit-content;
    max-height: 80px;
    min-height: 60px;
    align-items: center;
  }

  .Table_Row_Events:hover {
    border: 2px solid #40a6ff;
    border-radius: 5px;
    cursor: pointer;
  }

  .list-container {
    display: flex;
    font-size: 18px;
    background-color: #eee;
    flex-direction: column;
  }
  
  .item-container {
    background-color: #fff;
    border: 1px solid black;
    padding: 25px 70px;
    margin: 15px 50px;
  }  
}
