@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.moderation-list {
  .categories-label {
    width: fit-content;
    min-width: 155px;
    height: fit-content;
    line-height: 18px;
    text-align: center;
    padding: 10px 10px 15px;
    margin: 10px 10px 0px 0px;
    background: #e8e8e8;
    border: 1px solid #253645;
    border-radius: 10px;
    font-weight: bold;
  }
  .activeTab {
    background: #ffeaea !important;
    border-color: #ff0000 !important;
  }

  .moderation-list-heading {
    margin-top: 40px;
    font-size: 35px;
    line-height: 100%;
    height: 22px;
  }
  .moderation-list-search {
    .moderation-list-search-box {
      border-radius: 8px;
    }
  }
  .moderation-list-center-container {
    background-color: $bg-quaternary-color;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
      rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-top: 22px;
    border-radius: 10px;

    .thirty-chars {
      width: 45 ch;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .moderation-list-header {
      margin: 8px;
    }

    .moderation-list-table {
      min-height: 55vh;
      max-height: 55vh;
      overflow: auto;

      .active {
        border: 2px solid #40a6ff !important;
        border-radius: 10px !important;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
          rgba(17, 17, 26, 0.05) 0px 8px 32px !important;
      }

      .moderation-list-table-row {
        border: 2px solid transparent;
        border-radius: 10px;
        height: 7vh;
        &:hover {
          @extend .active;
        }

        .moderation-list-table-event-name {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .icon-editdel {
          cursor: pointer;
        }

        .text-italic {
          color: red;
          font-style: italic;
        }
      }
    }
  }
}

.moderation-wrapper {
  min-height: 80vh;
  background-color: white;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .categories-label {
    width: fit-content;
    min-width: 155px;
    height: fit-content;
    line-height: 18px;
    text-align: center;
    padding: 10px 10px 15px;
    margin: 10px 10px 0px 0px;
    background: #e8e8e8;
    border: 1px solid #253645;
    border-radius: 10px;
    font-weight: bold;
  }
  .active {
    background: #ffeaea !important;
    border-color: #ff0000 !important;
  }
  .breadcrumb-profile {
    font-size: 32px;
  }
}
.user-preview {
  background-color: white;
  height: fit-content;
  text-align: left;
  border-radius: 10px;
  padding-right: 5px;
  margin-top: 30px;
  margin-right: 5px;
}

.status {
  background-color: #fccec0;
  border-radius: 5px;
}
