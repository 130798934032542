@import "../styles/variables";


.Font_Primary_Color {
    color: $font-primary-color;
}
.Font_Secondary_Color {
    color: $font-secondary-color;
}
.Font_Tertiary_Color {
    color: $font-tertiary-color;
}
.Font_Quaternary_Color {
    color: $font-quaternary-color;
}
.Font_Quinary_Color {
    color: $font-quinary-color;
}

.Error_Color{
    color: $error-color;
}
.Bg_Primary_Color {
    background-color: $bg-primary-color;
}
.Bg_Secondary_Color {
    background-color: $bg-secondary-color;
}
.Bg_Tertiary_Color {
    background-color: $bg-tertiary-color;
}
.Bg_Quaternary_Color {
    background-color: $bg-quaternary-color;
}
.Bg_Quinary_Color {
    background-color: $bg-quinary-color;
}
.Bg_Senary_Color {
    background-color: $bg-senary-color;
}
.Bg_Btn_Admin{
    background-color: $bg-btn-admin;
}
.Bg_Btn_User{
    background-color: $bg-btn-user;
}