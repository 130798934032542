@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.DetailsEvent_Center_Container {
  background-color: $bg-quaternary-color;
  box-shadow: 0 0 40px rgba(33, 33, 33, 0.2);
  min-height: 72vh;
  border-radius: 10px;
}

.Table_Row_Events:hover {
  border: 2px solid #40a6ff;
  border-radius: 5px;
  cursor: pointer;
}

.table-header {
  color: #9da4aa;
  word-break: break-all;
}

.Table_Row_Events {
  border-radius: 5px;
  height: fit-content;
  max-height: 80px;
  min-height: 60px;
}

.Search_Box {
  border-radius: 8px;
}

.Search_Box_Button {
  border-radius: 8px;
}

.Event_Title {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-wraper {
  min-height: 55vh;
  max-height: 55vh;
  overflow: auto;
  .active {
    border: 2px solid #40a6ff;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
}
