@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.message-history-wrapper {
  height: fit-content;
}

.date-label-div {
  display: flex;
  justify-content: center;
  width: 100%;
}

.date-label {
  display: inline-block;
  background-color: white;
  color: black;
  font-weight: normal;
  padding: 6px 12px;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  text-align: center;
}

.message-container {
  background-color: #ffffff;
  border: 1px solid #333;
  padding: 10px;
  display: flex;
  border-radius: 4px;
  flex-direction: column;

  .user-info {
    display: flex;
    align-items: center;
    margin-right: 10px;

    .user-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      font-weight: bold;
      margin-right: 10px;
    }

    .username {
      color: #000;
      font-weight: bold;
      align-content: center;
      justify-content: center;
    }
  }

  .content {
    flex-grow: 1;
    color: #000;
    align-content: right;
    padding: 8px 0;
  }

  .timestamp {
    color: #999;
    font-size: 12px;
    text-align: right;
  }
}
