@import '../../../styles/colors';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.navbar {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 520px;
}

.menu-bars {
  font-size: 2rem;
}

.disabled-link {
  cursor: default;
  color: #e3e3e3 !important;
  img {
    filter: invert(99%) sepia(6%) saturate(142%) hue-rotate(228deg)
      brightness(200%) contrast(78%);
  }
}

.nav-menu {
  background-color: $bg-quaternary-color;
  width: 230px;
  height: 80%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 40px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: $font-secondary-color;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.nav-text a:hover {
  color: $font-primary-color;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.nav-menu-items {
  width: 100%;
  padding: 0px;
}

span {
  margin-left: 16px;
}
.logo {
  padding: 8px 0px 8px 40px;
}
.Sidebar_Submenu a {
  text-decoration: none;
  color: $font-secondary-color;
  padding-left: 64px;
}
.Sidebar_Submenu:hover {
  text-decoration: none;
  color: $font-primary-color;
}
.target:active {
  color: $font-primary-color;
}
.target:target {
  color: $font-primary-color;
}
.target:focus {
  color: $font-primary-color;
}
.Active {
  color: $font-primary-color;
}
