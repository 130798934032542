@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.moderation-list {
  .categories-label {
    width: fit-content;
    min-width: 155px;
    height: fit-content;
    line-height: 18px;
    text-align: center;
    padding: 10px 10px 15px;
    margin: 10px 10px 0px 0px;
    background: #e8e8e8;
    border: 1px solid #253645;
    border-radius: 10px;
    font-weight: bold;
  }
  .activeTab {
    background: #ffeaea !important;
    border-color: #ff0000 !important;
  }
  .message-wrapper {
    min-height: fit-content;
    background-color: white;
    border-radius: 10px;

    .active {
      background: #ffeaea !important;
      border-color: #ff0000 !important;
    }

    label {
      color: #5b5b5b;
    }

    .message-label {
      width: fit-content;
      min-width: 155px;
      height: fit-content;
      line-height: 18px;
      text-align: center;
      padding: 10px 10px 15px;
      background: #e8e8e8;
      border: 1px solid #253645;
      border-radius: 10px;
      font-weight: bold;
    }

    .thirty-chars {
      width: 60ch;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .twitter-div {
      width: 30vw;
    }
  }
  .message-history-container {
    background: #e5e5e5;
    max-height: 95vh;
    border-radius: 8px;
    overflow-y: scroll;
  }

  .input-feedback {
    color: rgb(235, 54, 54);
    font-size: 14px;
    margin-bottom: 20px;
  }
}
