@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: $bg-primary-color;
  border-color: $bg-primary-color;
}
.page-link {
  position: relative;
  display: block;
  color: $font-primary-color;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.page-link:hover {
  z-index: 2;
  color: $bg-primary-color;
  text-decoration: none;
  background-color: #e9ecef;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border: 0px solid #dee2e6 !important;
}
.page-link:focus {
  z-index: 3;
  color: $font-primary-color;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: none;
}
