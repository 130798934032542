@import "mixins";

.Font_Black{
    font-family: 'Roboto_Black';
}
.Font_BlackItalic{
    font-family: 'Roboto_BlackItalic';
}
.Font_Bold{
    font-family: 'Roboto_Bold';
}
.Font_BoldItalic{
    font-family: 'Roboto_BoldItalic';
}
.Font_Italic{
    font-family: 'Roboto_Italic';
}
.Font_Light{
    font-family: 'Roboto_Light';
}
.Font_LightItalic{
    font-family: 'Roboto_LightItalic';
}
.Font_Medium{
    font-family: 'Roboto_Medium';
}
.Font_MediumItalic{
    font-family: 'Roboto_MediumItalic';
}
.Font_Regular{
    font-family: 'Roboto_Regular';
}
.Font_Thin{
    font-family: 'Roboto_Thin';
}
.Font_ThinItalic{
    font-family: 'Roboto_ThinItalic';
}




