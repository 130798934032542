.notifications-wrapper {
  min-height: fit-content;
  background-color: white;
  border-radius: 10px;

  .active {
    background: #ffeaea !important;
    border-color: #ff0000 !important;
  }
  
  label {
    color: #5b5b5b;
  }
  
  .notification-label {
    width: fit-content;
    min-width: 155px;
    height: fit-content;
    line-height: 18px;
    text-align: center;
    padding: 10px 10px 15px;
    background: #e8e8e8;
    border: 1px solid #253645;
    border-radius: 10px;
    font-weight: bold
  }

  .thirty-chars {
    width: 60ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .twitter-div {
    width: 30vw;
  }
}

.notification-alert {
  color: #f2421d;
}

.send-button {
  width: fit-content;
  min-width: 70px;
}

.notification-textarea {
  max-height: 70px;
  min-height: 70px !important;
}
