@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.provider-list {
  .provider-list-heading {
    margin-top: 40px;
    font-size: 35px;
    line-height: 100%;
    height: 22px;
  }
  .provider-list-search {
    .provider-list-search-box {
      border-radius: 8px;
    }
  }
  .provider-list-center-container {
    background-color: $bg-quaternary-color;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
      rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-top: 22px;
    height: 80vh;
    .provider-list-header {
      margin-right: 8px;
    }
    .provider-list-table {
      max-height: 95%;
      overflow: auto;

      .active {
        border: 2px solid #40a6ff !important;
        border-radius: 10px !important;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
          rgba(17, 17, 26, 0.05) 0px 8px 32px !important;
      }

      .provider-list-table-row {
        border: 2px solid transparent;
        border-radius: 10px;
        height: 10vh;
        &:hover {
          @extend .active;
        }
        .provider-list-table-event-title {
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .provider-list-table-event-url {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .icon-editdel {
          cursor: pointer;
        }
      }
    }
  }
}
