@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.Center_Container {
  background-color: $bg-quaternary-color;
  box-shadow: 0 0 40px rgba(33, 33, 33, 0.2);
  margin-top: 24px;
}
.Provider_Name {
  margin-top: 40px;
  font-size: 35px;
  line-height: 100%;
  height: 22px;
  overflow-wrap: break-word;
  @include media-breakpoint-down(xxl) {
    font-size: 32px;
  }
  @include media-breakpoint-down(1xl) {
    font-size: 26px;
  }
  @include media-breakpoint-down(lg) {
    font-size: 22px;
  }
}
.Color_Picker_Bar,
.Color_Picker_Font {
  margin-bottom: 38px;
}
.error-message {
  background-color: rgb(250, 223, 223);
  border: 1px solid rgb(250, 223, 223);
  border-radius: 4px;
}
