$font-primary-color: #F2421D;
$font-secondary-color: #7B7B7B;
$font-tertiary-color: #000000;
$font-quaternary-color: #FFFFFF;
$font-quinary-color: #9DA4AA;

$bg-primary-color: #F2421D;
$bg-secondary-color: #7B7B7B;
$bg-tertiary-color: #000000;
$bg-quaternary-color: #FFFFFF;
$bg-quinary-color: #9DA4AA;
$bg-senary-color: #E5E5E5;

$bg_btn_admin:#3CCFA5;
$bg_btn_user:#BEBEBE;


$error-color:red;

$grid-breakpoints: ( //
  xs: 0, // Extra small screen / phone
  sm: 768px, // Small screen / phone
  md: 1024px, // Medium screen / tablet
  lg: 1280px, // Large screen / desktop
  1xl: 1440px,
  xl: 1600px, // Extra large screen / wide desktop
  xxl: 1920px // Extra Extra large screen
);