.statistics-container {
  background-color: white;
  min-height: 90vh;
  border-radius: 10px;

  .active {
    background: #ffeaea !important;
    border-color: #ff0000 !important;
  }

  label {
    color: #5b5b5b;
  }

  .stats-label {
    width: fit-content;
    min-width: 155px;
    height: fit-content;
    line-height: 18px;
    text-align: center;
    padding: 12px 10px;
    background: #e8e8e8;
    border: 1px solid #253645;
    border-radius: 10px;
  }

  .upload-icon {
    width: 32px !important;
    height: 32px !important;
    cursor: pointer;
  }
}
