@import '../../../styles/colors';

.Display_Btn {
  width: 100%;
  padding: 10px 15px;
  background-color: $bg-quaternary-color;
  color: $font-primary-color;
  border: 1px solid $font-primary-color;
  border-radius: 4px;
  transition: ease-in-out background-color 250ms, ease-in-out color 250ms;
  &:focus-visible {
    outline: none;
    background-color: $font-primary-color;
    border: 1px solid $font-primary-color;
    color: $font-quaternary-color;
  }

  &:hover {
    cursor: pointer;
    background-color: $bg-primary-color;
    border: 1px solid $font-primary-color;
    color: $font-quaternary-color;
  }
}

.disabled,
.disabled:hover {
  background-color: #ee9988 !important;
  border: 1px solid #ee9988 !important;
  color: $font-quaternary-color !important;
}

.not_active,
.not_active:hover {
  background-color: transparent;
  border: 1px solid #8c8c8c !important;
  color: $font-secondary-color !important;
}

.final,
.final:hover {
  background-color: #424242;
  border: 1px solid #424242;
  color: $font-quaternary-color !important;
}
