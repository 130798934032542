@import '../../styles/variables';
@import '../../styles/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.crumb {
  margin-top: 40px;
  font-size: 26px;
  line-height: 100%;
  height: 22px;
  overflow-wrap: break-word;
}
.primary-associate-w2w {
  position: relative;
  .associate-w2w {
    position: absolute;
    top: 6rem;
    border-radius: 10px;
    background-color: white;
    font-size: 11px;
  }
}
