.breadcrumb {
  font-size: 32px;
}

.data-feeds {
  background-color: white;
  height: max-content;
  color: #000;

  .comment-switch {
    height: 30px;
  }

  .refresh-button {
    background: #f2421d !important;
    border: none;
    border-radius: 6px;
    margin-bottom: 6px;
  }

  .svg-icon {
    width: 1.2em;
    height: 1.2em;
  }

  .svg-icon path,
  .svg-icon polygon,
  .svg-icon rect {
    fill: #ffffff;
  }

  .svg-icon circle {
    stroke: #ffffff;
    stroke-width: 2;
  }

  .form-control {
    background-color: #f5f8fb;
    &:focus {
      border: 1px solid black;
      box-shadow: none;
    }
  }

  .threads-header {
    display: flex;
  }

  .thread-list {
    max-height: 300px;
    overflow: auto;
    padding-right: 0px;
  }

  .active-thread {
    background-color: rgba(60, 207, 165, 0.2);
  }

  .selected-thread {
    background-color: rgba(217, 238, 30, 0.445);
  }

  .thread-data {
    width: 100%;
    height: 33px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px;
    border: 1px solid #ededed;
    border-radius: 4px 4px 0px 0px;
    cursor: pointer;

    &:hover {
      @extend .selected-thread;
    }
  }

  .data-feed-form-label {
    font-weight: 700;
    font-size: 16px;
  }
  .data-feed-form-data {
    word-break: break-all;
  }

  .data-feed-submit {
    width: 100px !important;
    height: 30px;
    padding: 0;
  }
}
