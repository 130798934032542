.create-poll {
  background-color: #ffffff;
  height: max-content;
  border-radius: 10px;
  max-width: 1000px;
  position: relative;

  .event-footer {
    position: absolute;
    width: 100%;
    left: 12px;
  }

  .start-time-label {
    font-weight: 700;
    margin-bottom: -24px;
  }

  .status-label {
    min-width: 78px;
    max-width: 78px;
    height: 22px;
    color: #fff;
    border-radius: 2px;
    font-size: 14px;
    text-align: center;
  }

  .ttc-main-label {
    font-weight: 700;
  }

  .template-button-text {
    font-weight: 700;
  }

  .ttc-label {
    padding-top: 10px;
    font-style: italic;
  }

  .react-datepicker-popper {
    width: 400px;
  }

  .active {
    background: #ffeaea !important;
    border-color: #ff0000 !important;
  }

  label {
    color: #000000;
  }

  .gamification-label {
    width: fit-content;
    min-width: 155px;
    height: fit-content;
    line-height: 18px;
    text-align: center;
    padding: 10px 10px 15px;
    background: #e8e8e8;
    border: 1px solid #253645;
    border-radius: 10px;
    font-weight: bold;
  }

  .selection-label {
    width: fit-content;
    min-width: 155px;
    height: fit-content;
    text-align: center;
    padding: 5px 0px 5px;
    background: #e8e8e8;
    border: 1px solid #253645;
    border-radius: 10px;
    font-weight: bold;
  }

  .custom-header {
    font-size: 15px;
    border-radius: 10px;
    font-weight: 700;
    color: #9da4aa;
    border-bottom: 1px solid #e4e4f1;
  }

  .poll-section {
    height: max-content;

    .status-info {
      .status-info-title {
        font-weight: 700;
      }

      .color-box {
        width: 12px;
        height: 12px;
        border: 1px solid #c4c4c4;
      }
    }

    .bold-text {
      font-weight: 700;
    }
    .option-input {
      max-width: 70px;
      max-height: 35px;
      align-items: center;
      margin-bottom: 0px;
      background: #e8e8e8;
    }
    .d-flex {
      align-items: center;
    }
    th {
      padding-right: 30px;
    }
    td {
      padding: 5px 30px 5px 0px;
    }
    .Table_Row_Events {
      border-radius: 5px;
      height: fit-content;
      max-height: 80px;
      min-height: 60px;
      align-items: center;
    }
    .Table_Row_Events:hover {
      border: 2px solid #40a6ff;
      border-radius: 5px;
      cursor: pointer;
    }
    .option_question {
      max-width: 200px;
      max-height: 35px;
      align-items: center;
      margin-bottom: 0px;
      background: #e8e8e8;
    }
  }
}
