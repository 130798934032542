.dropdown {
  width: 100%;

  .dropdown-toggle {
    width: 80%;
    height: 20px;
    font-size: 10px;
    padding: 0;
    border: none;

    &:hover,
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .dropdown-menu {
    font-size: 10px;
    min-width: 86%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;

    .dropdown-item {
      padding: 4px;
    }
  }
}

.dropdown-custum-button {
  width: 80%;
  height: 20px;
  font-size: 10px;
  color: #ffffff;
  padding: 0;
  border: none;
  cursor: default !important;
}
