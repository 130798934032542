@import '../../styles/colors';
.container {
  height: 100vh;
}
h1 {
  text-align: center;
}

.Login_Form {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

label,
input {
  display: block;
  width: 100%;
}

input {
  overflow: hidden;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #777;
  font-family: 'Roboto_Regular';
}

input.error {
  border-color: red;
}

.input-feedback {
  color: rgb(235, 54, 54);
  font-size: 14px;
  margin-bottom: 20px;
}

.login-button {
  width: 30%;
  padding: 10px 15px;
  background-color: $bg-primary-color;
  color: white;
  border: 1px solid $font-primary-color;
  border-radius: 4px;
  transition: ease-in-out background-color 250ms, ease-in-out color 250ms;
  &:focus-visible {
    outline: none;
    background-color: white;
    border: 1px solid $font-primary-color;
    color: $font-primary-color;
  }
}

.login-button:hover {
  cursor: pointer;
  background-color: white;
  border: 1px solid $font-primary-color;
  color: $font-primary-color;
}

img {
  display: inline-block;
}
.Forgot_Pwd {
  text-decoration: none;
  border-bottom: 1px solid $font-primary-color;
  &:focus-visible {
    outline: none;
    text-decoration: none;
    color: $font-primary-color;
  }
}
.Forgot_Pwd:hover {
  text-decoration: none;
  color: $font-primary-color;
}
.error-message {
  background-color: rgb(250, 223, 223);
  border: 1px solid rgb(250, 223, 223);
  border-radius: 4px;
}
.Disabled {
  background-color: #ee9988;
  border: 1px solid #ee9988;
  color: white;
  cursor: default;

  &:hover {
    @extend .Disabled;
  }
}

.password-group {
  width: 300px;
}
