@import '../src/styles/fonts';
@import '../src/styles/colors';
@import '~bootstrap/scss/bootstrap';
@import './styles/variables';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-senary-color;
}
input {
  display: block;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 1px;
  border: 1px solid #777;
  font-family: 'Roboto_Regular';
}

.Preview_Container {
  position: fixed;
  right: 0;
}
