@mixin font-face($name, $path, $weight: null, $style: null, $exts: ttf) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@include font-face("Roboto_Black", fonts/Roboto-Black, regular,normal,ttf);
@include font-face("Roboto_BlackItalic", fonts/Roboto-BlackItalic, regular,normal,ttf);
@include font-face("Roboto_Bold", fonts/Roboto-Bold, regular,normal,ttf);
@include font-face("Roboto_BoldItalic", fonts/Roboto-BoldItalic, regular,normal,ttf);	
@include font-face("Roboto_Italic", fonts/Roboto-Italic, regular,normal,ttf);
@include font-face("Roboto_Light", fonts/Roboto-Light, regular,normal,ttf);
@include font-face("Roboto_LightItalic", fonts/Roboto-LightItalic, regular,normal,ttf);
@include font-face("Roboto_Medium", fonts/Roboto-Medium, regular,normal,ttf);
@include font-face("Roboto_MediumItalic", fonts/Roboto-MediumItalic, regular,normal,ttf);
@include font-face("Roboto_Regular", fonts/Roboto-Regular, regular,normal,ttf);
@include font-face("Roboto_Thin", fonts/Roboto-Thin, regular,normal,ttf);
@include font-face("Roboto_ThinItalic", fonts/Roboto-ThinItalic, regular,normal,ttf)

