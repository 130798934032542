.custom-header {
  height: 40px;
  margin: 0;
  font-size: 10px;
  border-radius: 10px;
  font-weight: 700;
  color: #9da4aa;
  border-bottom: 1px solid #e4e4f1;
  align-content: center;
}

.custom-row {
  height: 40px;
  margin: 12px 0;
  font-size: 10px;
  border-radius: 10px;
  align-items: center;

  &:hover {
    border: 1px solid #40a6ff;
  }

  .grab {
    width: 12px;
    height: 12px;
    cursor: -webkit-grab;
    cursor: grab;
  }
}

.active-row {
  border: 1px solid #40a6ff;
  cursor: pointer;
}

.td-drag {
  max-width: 2%;
  margin-left: 4px;
}

.td-index {
  max-width: 2%;
}

.td-question {
  max-width: 20%;
}

.td-template {
  max-width: 8%;
}

.td-start {
  max-width: 12%;
}

.td-ttc {
  max-width: 6%;
}

.td-end {
  max-width: 12%;
}

.td-value {
  max-width: 4%;
}

.td-status {
  max-width: 10%;
}

.td-correct-answer {
  max-width: 10%;
}

.td-answer {
  max-width: 10%;
}

.td-modify {
  max-width: 4%;
  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
    &:focus,
    &:hover {
      filter: invert(31%) sepia(68%) saturate(3270%) hue-rotate(208deg)
        brightness(98%) contrast(104%);
    }
  }
}
