@import '../../../styles/variables.scss';
@import '../../../styles/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.Preview_Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border: 10px solid $font-tertiary-color;
  width: 220px;
  border-radius: 30px;
}
.Preview_Image {
  // max-width: 100%;
  width: 100%;
  height: 100%;
  position: relative;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.Preview_Image_Wrapper {
  position: relative;
  height: 346px;
}
.Preview_Image_Title {
  position: absolute;
  bottom: 108px;
  left: 10px;
  color: white;
  max-width: 170px;
  word-wrap: break-word;
  height: 30px;
  font-size: 15px;
}
.Preview_Image_Description {
  word-wrap: break-word;
  height: 30px;
  font-size: 7px;
  position: absolute;
  bottom: 58px;
  left: 10px;
  color: white;
  max-width: 130px;
  word-wrap: break-word;
}
.Bottom_Nav {
  max-width: 100%;
  border-radius: 30px;
}
.Step_Number {
  margin-top: 30px;
  width: 16px;
  height: 1rem;
  background: $bg_secondary_color;
  border-radius: 50%;
  margin-right: 0px;
  box-shadow: inset 0 0 0 9px $bg_secondary_color;
  background: $bg_secondary_color;
  display: inline-block;
  position: relative;
  top: -0.2em;
  vertical-align: top;
  text-align: center;
  transition: all 250ms ease;
}
.Step_Number_Active {
  margin-top: 30px;
  width: 16px;
  height: 1rem;
  background: $bg-primary-color;
  border-radius: 50%;
  margin-right: 0px;
  box-shadow: inset 0 0 0 9px $bg-primary-color;
  background: $bg-primary-color;
  display: inline-block;
  position: relative;
  top: -0.2em;
  vertical-align: top;
  text-align: center;
  transition: all 250ms ease;
}
.Step_Number_Wrapper {
  display: flex;
  justify-content: center;
}
.Pages_Labels {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  border-radius: 5px;
  max-width: 100%;
  background-color: $bg-quinary-color;
  color: #253645;
}
.Pages_Labels_Active {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  border-radius: 5px;
  max-width: 100%;
  background-color: $bg-primary-color;
  color: $font-quaternary-color;
}
.Preview_Center_Container {
  background-color: $bg-quaternary-color;
  box-shadow: 0 0 40px rgba(33, 33, 33, 0.2);
}
.Preview_Main_Container {
  margin-top: 40px;
  margin-bottom: 15px;
}
.Timer_Icon {
  display: flex;
  position: absolute;
  bottom: 58px;
  right: 10px;
}
.Info_Icon {
  display: flex;
  position: absolute;
  bottom: 20px;
  right: 10px;
}

.create-event-position {
  position: relative;
  top: 100px;
}
