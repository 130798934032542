.breadcrumb {
  font-size: 32px;
  background-color: #e5e5e5;
}

.gamification {
  .poll-section {
    height: max-content;
    // min-width: 1024px;
    background-color: #fff;
    box-shadow: 0px 4px 40px #ececf7;
    border-radius: 10px;

    .status-info {
      .status-info-title {
        font-weight: 700;
      }

      .color-box {
        width: 12px;
        height: 12px;
        border: 1px solid #c4c4c4;
      }
    }

    .bold-text {
      font-weight: 700;
    }

    .col {
      padding: 0;
    }

    .table-body {
      max-height: 50vh;
      min-height: 40vh;
      overflow: auto;
    }

    .add-new {
      width: max-content !important;
      height: 30px;
      padding: 2px;
      font-size: 14px;
    }
  }
}

.delete-poll-modal {
  .btn {
    background-color: #fff;
    color: #f2421d;
    border: 1px solid #f2421d;

    &:focus,
    &:hover {
      background-color: #f2421d;
      color: #fff;
      box-shadow: none;
      outline: none;
    }
  }
  .modal-footer {
    border: none;
    justify-content: center;
  }
}

.status-info-modal {
  height: max-content;

  .info-button {
    color: #fff;
    padding: 0;
    text-align: center;
    align-self: center;
  }
}

.default-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.confirm-answer-modal {
  font-weight: 700px;
}
