@import '../../../styles/variables';
@import '../../../styles/mixins';

.FormField__Input__Text {
  display: block;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 1px;
  border: 1px solid #777;
  display: block;
  background-color: $bg-senary-color;
  &:disabled {
    background-color: #fff;
    color: #979797;
    border: 1px solid #979797;
  }
}
.Error {
  border-color: $error-color;
}
.FormField__Label {
  display: block;
  width: 100%;
  height: 22px;
}
.FormField_Textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 1px;
  border: 1px solid #777;
  display: block;
  background-color: $bg-senary-color;
  &:disabled {
    background-color: #fff;
    color: #979797;
    border: 1px solid #979797;
  }
}
.FormField_Textarea:focus {
  border: 1px solid black;
  box-shadow: none;
  background-color: $bg-senary-color;
}